<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row align="center" justify="center" class="text-center">
          <v-col cols="12">
            <v-avatar color="primary" size="100">
              <v-img :src="require('@/assets/images/person.jpg')" />
            </v-avatar>
          </v-col>

          <v-col cols="12">
            <div class="font-weight-bold main--text text-heading-7 font-italic">
              {{ user.last_name_kana }} {{ user.first_name_kana }}
            </div>
          </v-col>

          <v-col cols="12">
            <div class="font-weight-medium main--text lighten-2 text-heading-6">
              {{ user.company.display_name }}
            </div>
          </v-col>

          <v-col cols="12">
            <v-simple-table class="account-details-table">
              <template v-slot:default>
                <tbody>
                  <tr v-for="item in accountDetails" :key="item.name">
                    <td class="font-weight-bold text-left text-heading-4">
                      {{ item.text }}:
                    </td>
                    <td class="text-left text-heading-4">{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12">
            <v-btn color="secondary" to="/profile/edit" block text>
              {{ $t("edit_profile") }}
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" to="/profile/settings" block text>
              {{ $t("settings") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "ProfilePage",
  computed: {
    ...mapGetters(["user"])
  },
  data() {
    return {
      accountDetails: [
        {
          name: "id",
          text: this.$t("member_id"),
          value: "CP001"
        },
        {
          name: "joined_date",
          text: this.$t("member_since"),
          value: dayjs().format("YYYY-MM-DD")
        },
        {
          name: "account_type",
          text: this.$t("account_type"),
          value: "Business"
        }
      ]
    };
  }
};
</script>

<style scoped>
.account-details-table >>> td {
  border: unset !important;
}
</style>
